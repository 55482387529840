
import { Box, Grid, ResponsiveContext } from "grommet";
import styled from "styled-components";
import AttractionList from "./AttractionList";
import Map from "./Map";


const StyledBox = styled(Box)`
  display: inline-block;
`;
const StyledGrid = styled(Grid)`
  justify-content: center;
`;


const OfferDetails = ({attractions, coords}) => (
    <ResponsiveContext.Consumer>
    {(responsive) =>
      responsive === "small" ? (
        <Grid
          rows={["small", "medium", "medium"]}
          columns={["medium"]}
          gap="small"
          areas={[
            { name: "list", start: [0, 0], end: [0, 2] },
            { name: "map", start: [0, 2], end: [0, 2] },
          ]}
        >
          <Box gridArea="list" align="center" justify="center" alignContent="center">
            <AttractionList attractions={attractions} />
          </Box>
          <StyledBox gridArea="map" align="center" justify="center">
            <Map coords={coords} />
          </StyledBox>
        </Grid>
      ) : (
        <StyledGrid
          rows={["medium"]}
          columns={["medium", "large"]}
          gap="small"
          areas={[
            { name: "list", start: [0, 0], end: [1, 0] },
            { name: "map", start: [1, 0], end: [1, 0] },
          ]}
        >
          <Box gridArea="list" align="center" justify="center">
          <AttractionList attractions={attractions} />
          </Box>
          <StyledBox gridArea="map" align="center" justify="center">
          <Map coords={coords} />
          </StyledBox>
        </StyledGrid>
      )
    }
  </ResponsiveContext.Consumer>
)

export default OfferDetails;