import { Box, Heading, Text, Image } from "grommet";
import styled from "styled-components";
import logo from "../assets/images/white.png";
import abstract2 from "../assets/images/abstract_2.png";
import { HashLink } from "react-router-hash-link";

const StyledGrid = styled.div`
  margin-top: 20px;
  padding: 5% 10%;
  display: grid;
  grid-template-columns: 0.5fr, 1fr, 1fr, 1fr;
  grid-template-rows: 0.5fr, 1fr;

  .name {
    grid-column: 1/3;
    gridrow: 1/2;
  }

  .column1 {
    grid-column: 1/2;
    gridrow: 2/3;
  }

  .address {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .column2 {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .logo {
    grid-column: 4/4;
    grid-row: 2/3;
  }

  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;

    .address {
      margin-bottom: 40px;
    }
  }
`;

const StyledBox = styled(Box)`
  max-width: 100%;
`;

const StyledImage = styled(Image)`
  max-width: 100%;
`;
const StyledHashLink = styled(HashLink)`
  color: white;
  text-decoration: none;
  font-weight: 400;
`;

const Footer = (theme) => (
  <>
    <Image
      src={abstract2}
      alt="abstrakcyjny obrazek"
      height="200px"
      margin={{
        bottom: "-5px",
        left: "10%",
      }}
    />
    <Box id="contact" background={theme.theme.global.colors.brand}>
      <StyledGrid>
        <Box className="name">
          <Heading level="4" margin={{ bottom: "small" }}>
            Konkret Budownictwo Leśniak Mączka Spółka Jawna
          </Heading>
        </Box>
        <Box className="column1">
          <Heading level="4">Biuro Sprzedaży</Heading>
          <Text color="lightbrand" size="large" weight="bold">
            Kamil
          </Text>
          <Text
            size="xlarge"
            margin={{
              vertical: "small",
            }}
            weight="bold"
          >
            +48 571 026 144
          </Text>
          <Text color="lightbrand" size="large" weight="bold">
            Konrad
          </Text>
          <Text
            size="xlarge"
            margin={{
              vertical: "small",
            }}
            weight="bold"
          >
            +48 602 330 630
          </Text>
          <Text size="medium">biuro@konkretbudownictwo.pl</Text>
        </Box>
        <Box className="column2">
          <Heading level="4">Dział techniczny inwestycji</Heading>
          <Text color="lightbrand" size="large" weight="bold">
            Antoni
          </Text>
          <Text
            size="xlarge"
            margin={{
              vertical: "small",
            }}
            weight="bold"
          >
            +48 696 058 189
          </Text>
        </Box>
        <Box className="address">
          <Text
            size="medium"
            margin={{
              top: "medium",
            }}
          >
            Ochota 17D
          </Text>
          <Text size="medium">32-020 Wieliczka</Text>
          <Text
            size="medium"
            weight="bold"
            margin={{
              vertical: "small",
            }}
          >
            NIP: 6832114763
          </Text>
          <Text size="medium" weight="bold">
            REGON: 388877509
          </Text>
        </Box>
        <StyledBox className="logo" direction="row" alignSelf="center" height="90px">
          <StyledImage src={logo} alt="logo konkret budownictwo"/>
        </StyledBox>
      </StyledGrid>
      <Box pad="medium">
        <Box direction="row" gap="small" justify="center" margin="small">
          <StyledHashLink to="/#about-us"><Text size="small">O nas</Text></StyledHashLink>
          <StyledHashLink to="/investment#main"><Text size="small">Aktualne inwestycje</Text></StyledHashLink>
          <StyledHashLink to="#contact"><Text size="small">Kontakt</Text></StyledHashLink>
        </Box>
        <Text size="small" textAlign="center">
          Copyright 2021 by konkret-budownictwo.pl All rights reserved. Powered by Codeminer.
        </Text>
      </Box>
    </Box>
  </>
);

export default Footer;
