import { Box } from "grommet";
import Offer from "../components/common/Offer";
import SulkowZacisze from "../data/SulkowZaciszeData";
import Archive from "../components/Investment/Archive"
const SulkowZaciszePage = () => (
  <Box animation="fadeIn">
    <Offer pageData={SulkowZacisze} />
    <Archive></Archive>
  </Box>
);

export default SulkowZaciszePage;
