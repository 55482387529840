import { Image, Carousel } from "grommet";
import styled from "styled-components";

const StyledCarousel= styled(Carousel)`
height:100vh;
width:100%;
`

const Slider = ({ images }) => (
    <StyledCarousel fill play={5000} id="main">
        {
            images.map((i, k) => 
                <Image key={k} fit="cover" alt={`wizualizacja domu ${k}`} src={i} />
            )
        }
    </StyledCarousel>
  
);

export default Slider;
