import { Box, Grid, Heading, Image, ResponsiveContext } from "grommet";
import mainImage from "../../assets/images/sulkow-2/9.jpg";
import styled from "styled-components";
import React from "react";
import AdDescription from "./AdDescription";

const StyledGrid = styled(Grid)`
  margin: 0 auto;
  max-width: 100%;
`;

const StyledImg = styled(Image)`
  height: 100%;
  width: auto;
  vertical-align: middle;
  border: 0;
`;

const InvestmentAdd = () => {
  return (
    <Box>
      <Heading color="brand">Aktualne inwestycje</Heading>
      <ResponsiveContext.Consumer>
        {(responsive) =>
          responsive === "small" ? (
            <Box gridArea="text" background="brand" align="center" alignContent="center" pad="medium">
              <AdDescription />
            </Box>
          ) : (
            <StyledGrid
              rows={["medium"]}
              columns={["small", "small", "small", "small"]}
              justify="start"
              areas={[
                { name: "text", start: [0, 0], end: [2, 0] },
                { name: "image", start: [2, 0], end: [4, 0] },
              ]}
            >
              <Box gridArea="text" background="brand" align="center" alignContent="center" pad="medium">
                <AdDescription />
              </Box>
              <StyledImg src={mainImage} alt="Wizualizacja front reklama" gridArea="image" />
            </StyledGrid>
          )
        }
      </ResponsiveContext.Consumer>
    </Box>
  );
};

export default InvestmentAdd;
