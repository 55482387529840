import { Heading, Text } from "grommet";
import styled from "styled-components";
import { list } from "./AboutUs/list";

const ListWrapper = styled.div`
  margin: 50px 0;
  max-width: 1400px;
`;

const List = () => (
  <ListWrapper>
    <ul>
      {list.map((item, i) => (
        <li key={i}>
          <div>
            <Heading color="brand" level="4">
              {item.title}
            </Heading>
            <Text>{item.text}</Text>
          </div>
        </li>
      ))}
    </ul>
    {/* <Image gridArea="abstract" src={abstract} /> */}
  </ListWrapper>
);

export default List;
