import React from "react";
import { Box, Header, Menu, Nav, ResponsiveContext, Image, } from "grommet";
import logo from "../assets/images/white.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styled from "styled-components";

const StyledDropdHashLink = styled(HashLink)`
  color: white;
  text-decoration: none;
  font-weight: 600;
  :hover {
    border-bottom: 2px solid white;
    padding-bottom: 3px;
  }
`;
const StyledHashLink = styled(HashLink)`
  color: white;
  text-decoration: none;
  font-weight: 600;
  padding: 12px;
  :hover {
    border-bottom: 2px solid white;
    padding-bottom: 3px;
  }
`;
const StyledMenu = styled(Menu)`
  color: white;
  text-decoration: none;
  font-weight: 600;
  :hover {
    border-bottom: 2px solid white;
    padding-bottom: 3px;
  }
`;

const StyledDropdownHashLink = styled(HashLink)`
  color: black;
  text-decoration: none;
  font-weight: 400;
  :hover {
    border-bottom: 2px solid black;
    padding-bottom: 3px;
  }
`;

const Navbar = () => {
  return (
    <Header background="brand" sticky="scrollup" pad="medium">
      <Box direction="row" align="center" gap="small">
        <Link to="/">
          <Image src={logo} alt="konkret budownictwo logo" height="70px" />
        </Link>
      </Box>
      <ResponsiveContext.Consumer>
        {(responsive) =>
          responsive === "small" ? (
            <Menu
              dropBackground="secondary"
              label=""
              dropProps={{ align: { top: "bottom", left: "left" } }}
              items={[
                { label: <StyledDropdownHashLink to="/#about-us"> O nas </StyledDropdownHashLink> },
                {
                  label: <StyledDropdownHashLink to="/sulkow-zacisze#main"> Słoneczne Zacisze  </StyledDropdownHashLink>,
                },
                {
                  label: <StyledDropdownHashLink to="/investment#main"> Słoneczna Panorama </StyledDropdownHashLink>,
                },
                { label: <StyledDropdownHashLink to="#contact"> Kontakt </StyledDropdownHashLink> },
              ]}
            />
          ) : (
            <Nav direction="row">
              <StyledHashLink to="/#about-us"> O nas </StyledHashLink>
              <StyledMenu
                dropProps={{
                  align: { top: "bottom", left: "left" },
                  elevation: "xlarge",
                }}
                label="Inwestycje"
                dropBackground={{ color: "brand", opacity: "strong" }}
                items={[
                  { label: <StyledDropdHashLink to="/sulkow-zacisze#main"> Słoneczne Zacisze </StyledDropdHashLink> },
                  {
                    label: <StyledDropdHashLink to="/investment#sulkow">[Zakończone] Słoneczna Panorama</StyledDropdHashLink>,
                  },
                ]}
              />

              <StyledHashLink to="#contact"> Kontakt </StyledHashLink>
            </Nav>
          )
        }
      </ResponsiveContext.Consumer>
    </Header>
  );
};

export default Navbar;
