import { Box, Table, TableBody, TableCell, TableHeader, TableRow } from "grommet";
import styled from "styled-components";


const StyledTableRow = styled(TableRow)`
  height: 60px;
`;

const StyledLink = styled.a`
border:0;
color:#2a388b;`;

const StyledTable = styled(Table)`
  display:block;
  width:100%;
  overflow-x: scroll;
`


const getHousePrice = (house) => {
  switch(house.houseState) {
    case 'avaliable':
      return `${house.price} ${house.currency}`;
    case 'reserved':
      return 'Zarezerwowane';
    case 'pre-reserved':
        return 'Wstępna rezerwacja';
    default:
      return 'Sprzedane'
  }
};

const getSquareHousePrice = (house) => {
  switch(house.houseState) {
    case 'avaliable':
      return `${Math.floor((house.price / house.usableSurface) * 100) / 100} ${house.currency}`;
    case 'reserved':
      return 'Zarezerwowane';
    case 'pre-reserved':
        return 'Wstępna rezerwacja';
    default:
      return 'Sprzedane';;
  }
};

const PriceTable = ({ houses }) => (
  <Box align="center" pad="large">
    <StyledTable>
      <TableHeader>
        <StyledTableRow>
          <TableCell scope="col" border="bottom" align="center">
            Segment
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Powierzchnia działki
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Powierzchnia ogrodu
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Powierzchnia
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Powierzchnia uzytkowa
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Cena
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Cena za m²
          </TableCell>
          <TableCell scope="col" border="bottom" align="center">
            Plan
          </TableCell>
        </StyledTableRow>
      </TableHeader>
      <TableBody>
        {houses.map((house) => (
          <StyledTableRow key={house.id}>
            <TableCell border="bottom" scope="row" align="center">
              <strong>{house.id}</strong>
            </TableCell>
            <TableCell border="bottom" align="center">{`${house.plotSurface} ${house.symbol}`}</TableCell>
            <TableCell border="bottom" align="center">{`${house.gardenSurface} ${house.symbol}`}</TableCell>
            <TableCell border="bottom" align="center">{`${house.floorSurface} ${house.symbol}`}</TableCell>
            <TableCell border="bottom" align="center">{`${house.usableSurface} ${house.symbol}`}</TableCell>
            <TableCell border="bottom" align="center">
              {getHousePrice(house)}
            </TableCell>
            <TableCell border="bottom" align="center">
              {getSquareHousePrice(house)}
            </TableCell>
            <TableCell scope="col" border="bottom" align="center">
            {house.houseState !== "avaliable" ? (
              <></>
            ) : (
                <StyledLink href={house.offer} target="_blank" rel="noreferrer">
                  Wyświetl
                </StyledLink>
            )}
            </TableCell>

          </StyledTableRow>
        ))}
      </TableBody>
    </StyledTable>
  </Box>
);

export default PriceTable;
