import { Bus, Shop, Location, Car, Basket, Train } from "grommet-icons";
import fifthImage from "../assets/images/sulkow-2/13.jpg";
import fourthImage from "../assets/images/sulkow-2/10.jpg";
import firstImage from "../assets/images/sulkow-2/1.jpg";
import secondImage from "../assets/images/sulkow-2/3.jpg";
import thirdImage from "../assets/images/sulkow-2/9.jpg";
import view from "../assets/images/sulkow-2/rzut.jpg";
import offerA from "../assets/pdf/zacisze/dom_a.pdf";
import offerB from "../assets/pdf/zacisze/dom_b.pdf";
import offerC from "../assets/pdf/zacisze/dom_c.pdf";
class SlonecznaPanoramaData {
  Attractions = [
    { place: "Przystanek autobusowy", length: "450m", icon: <Bus size="medium" /> },
    { place: "Sklep", length: "450m", icon: <Basket size="medium" /> },
    { place: "Wjazd na autostradę", length: "5 km", icon: <Car size="medium" /> },
    { place: "Dworzec PKP w Wieliczce", length: "5.3 km", icon: <Train size="medium" /> },
    { place: "Centrum Handlowe Bonarka", length: "13.5 km", icon: <Shop size="medium" /> },
    { place: "Rynek Główny w Krakowie", length: "17 km", icon: <Location size="medium" /> },
  ];

  View = view;
  HousesTable = [
    {
      id: "A",
      plotSurface: 380.92,
      gardenSurface: 255.76,
      floorSurface: 104.27,
      usableSurface: 97.04,
      price: 900000,
      currency: "zł",
      symbol: "m²",
      houseState: "pre-reserved",
      offer: offerA,
    },
    {
      id: "B",
      plotSurface: 276.72,
      gardenSurface: 154.0,
      floorSurface: 119.97,
      usableSurface: 97.75,
      price: 875000,
      currency: "zł",
      symbol: "m²",
      houseState: "avaliable",
      offer: offerB,
    },
    {
      id: "C",
      plotSurface: 218.75,
      gardenSurface: 106.64,
      floorSurface: 119.97,
      usableSurface: 97.75,
      price: 845000,
      currency: "zł",
      symbol: "m²",
      houseState: "pre-reserved",
      offer: offerC,
    },
    {
      id: "D",
      plotSurface: 363.04,
      gardenSurface: 242.94,
      floorSurface: 119.97,
      usableSurface: 97.75,
      price: 885000,
      currency: "zł",
      symbol: "m²",
      houseState: "pre-reserved",
    },
    {
      id: "E",
      plotSurface: 359.35,
      gardenSurface: 229.25,
      floorSurface: 119.97,
      usableSurface: 97.75,
      price: 885000,
      currency: "zł",
      symbol: "m²",
      houseState: "pre-reserved",
    },
    {
      id: "F",
      plotSurface: 336.84,
      gardenSurface: 216.72,
      floorSurface: 119.97,
      usableSurface: 97.75,
      price: 885000,
      currency: "zł",
      symbol: "m²",
      houseState: "pre-reserved",
    },
  ];

  SliderImages = [firstImage, secondImage, thirdImage, fourthImage, fifthImage];

  InvestmentDescription = `
  Słoneczne Zacisze to przytulne osiedle domów jednorodzinnych w miejscowości Sułków, nieopodal Wieliczki. 
  Nasza inwestycja znajduje się w zacisznym i urokliwym miejscu, które jednocześnie zapewnia bliskość infrastruktury komunikacyjnej.
  Wykonany indywidualnie projekt budynków charakteryzuje się przemyślaną i funkcjonalną architekturą, zapewniającą komfort i 
  wygodę mieszkania. Domy wykończone są w podwyższonym standardzie, posiadają garaż (budynki B-F) lub wiatę (budynek A).
  Zastosowano między innymi: system rekuperacji powietrza, powiększoną izolację termiczną między segmentami - 53 cm, 
  docieplenie ścian zewnętrznych styropianem 20 cm, beton komórkowy i dachówkę betonową. Każdy z segmentów wyposażony jest w piec
   c.o. oraz posiada rozprowadzoną instalację ogrzewania podłogowego. Zakończenie inwestycji planowane jest na II kwartał 2026.
  `;

  StandardList = [
    "rekuperacja",
    "ogrzewanie podłogowe",
    "instalacja gazowa z piecem c.o",
    "instalacja elektryczna, domofon, internetowe łącze światłowodowe",
    "dachówka betonowa Brass w kolorze antracytowym",
    "garaż lub wiata",
    "beton komórkowy",
    "ocieplenie budynku: styropian o grubości 20 cm, tynk silikatowy",
    "ocieplenie poddasza: wełna mineralna",
    "odległość miedzy wewnętrzynmi segmentami 53cm",
    "droga wewnetrzna utwardzona - kostka eko",
    "miejsce parkingowe kostka brukowa",
    "tynki gipsowe wewnętrzne diament",
    "szambo lub kanalizacja",
    "fundamenty: styrodur XPS o grubości 12cm, izolacja pionowa, disperbit (masa bitumiczna)",
  ];
}

const SulkowZacisze = new SlonecznaPanoramaData();
export default SulkowZacisze;
