import {
  Box,
  Form,
  TextInput,
  FormField,
  TextArea,
  CheckBox,
  Button,
  Heading,
  Notification
} from "grommet";
import styled from "styled-components";
import React, { useState } from "react";
import sendEmail from "../services/emailService";

const StyledFormField = styled(FormField)`
  width: 45%;
`;

const TextAreaFormField = styled(FormField)``;

const StyledTextArea = styled(TextArea)`
  height: 200px;
`;

const ContactForm = () => {
  const [checked, setChecked] = useState(false);
  const [showError, setError] = useState(false);
  const [showSuccess, setSucces] = useState(false);
  const [value, setValue] = React.useState({
    rodo: false,
    from: "",
    email: "",
    message: ""
  });

  const handleChange = () => setChecked(!checked);
  const handleSubmit = () =>
    sendEmail(value).then(result => {
      setSucces(result);
      setError(!result);
    });
  const onSuccessClose = () => setSucces(undefined);
  const onErrorClose = () => setError(undefined);

  return (
    <>
      <Heading color="brand">
        Kontakt
      </Heading>
      {showSuccess && (
        <Notification
          toast
          title="Sukces"
          status="normal"
          message="Dziękujemy za kontakt, postaramy się odpowiedzieć na twoją wiadomość do 24 godzin."
          onClose={onSuccessClose}
        />
      )}
      {showError && (
        <Notification
          toast
          title="Błąd"
          status="critical"
          message={`Przepraszamy, wystapił błąd przy wysyłaniu wiadomości. Proszę wyślij wiadomość bezpośrednio na adres email: biuro@konkretbudownictwo.pl. Twoja wiadomość: ${value.message}`}
          onClose={onErrorClose}
        />
      )}
      <Form
        value={value}
        onChange={nextValue => setValue(nextValue)}
        onSubmit={handleSubmit}
        margin="medium"
      >
        <Box
          margin={{
            horizontal: "large",
            top: "large"
          }}
          direction="column"
          gap="large"
        >
          <Box direction="row" justify="between">
            <StyledFormField label="Imię" name="from" width="40%" required>
              <TextInput name="from" />
            </StyledFormField>
            <StyledFormField label="Email" name="email" width="40%" required>
              <TextInput name="email" />
            </StyledFormField>
          </Box>
          <TextAreaFormField
            label="Wiadomość"
            name="message"
            width="100%"
            required
          >
            <StyledTextArea
              placeholder="Napisz do nas"
              name="message"
              height="200px"
            />
          </TextAreaFormField>
          <CheckBox
            checked={checked}
            name="rodo"
            label="Wyrażam zgodę na przetwarzanie danych osobowych. Administratorem danych osobowych jest Konkret Budownictwo Leśniak Mączka Spółka Jawna. Dane wpisane w formularzu kontaktowym będą przetwarzane w celu udzielenia odpowiedzi na przesłane zapytanie zgodnie z regulaminem"
            onChange={handleChange}
            required
          />
          <Box justify="end" direction="row">
            <Button
              primary
              label="Wyślij"
              type="submit"
              color="brand"            />
          </Box>
        </Box>
      </Form>
    </>
  );
};

export default ContactForm;
