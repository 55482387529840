import { Image, Box } from "grommet";
import styled from "styled-components";
import PriceTable from "./PriceTable";
import Standard from "./Standard";
import Description from "./Description";
import OfferDetails from "./OfferDetails";
import Slider from "./Slider";

const StyledImage = styled(Image)`
  margin: 0 auto;
`;


const Offer = ({ pageData }) => (
  <Box overflow="hidden">
    <Slider images={pageData.SliderImages}></Slider>
    <Box margin="xlarge" gap="large">
      <Description text={pageData.InvestmentDescription} />
      <StyledImage src={pageData.View} alt="rzut działki" width="80%" />
      <PriceTable houses={pageData.HousesTable} />
      <OfferDetails attractions={pageData.Attractions} coords= {[49.996037, 20.106799]}/>
      <Standard standardList={pageData.StandardList}/>
    </Box>
  </Box>
);

export default Offer;
