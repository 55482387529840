import { Box } from "grommet";
import Offer from "../components/common/Offer";
import ArchiveGallery from "../components/Investment/ArchiveGallery";
import SlonecznaPanorama from "../data/SlonecznaPanoramaData";

const InvestmentPage = () => (
  <Box animation="fadeIn">
    <Offer pageData={SlonecznaPanorama} />
    <ArchiveGallery />
  </Box>
);

export default InvestmentPage;
