
import o1 from "../../assets/images/sulkow2/archive/october/1.jpg";
import o2 from "../../assets/images/sulkow2/archive/october/2.jpg";
import o3 from "../../assets/images/sulkow2/archive/october/3.jpg";
import o4 from "../../assets/images/sulkow2/archive/october/4.jpg";
import o5 from "../../assets/images/sulkow2/archive/october/5.jpg";
import o6 from "../../assets/images/sulkow2/archive/october/6.jpg";
import o7 from "../../assets/images/sulkow2/archive/october/7.jpg";
import { Box, Heading } from "grommet";
import "./archiveGallery.css";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";


const StyledHeading = styled(Heading)`
  margin: 20px auto;
`;

const september2024 = [
  {
    original: o1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: o2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: o3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: o4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: o5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
  {
    original: o6,
    originalAlt: "Zdjęcie nr 6 domu jednorodzinnego",
  },
  {
    original: o7,
    originalAlt: "Zdjęcie nr 7 domu jednorodzinnego",
  },
];

const boxMargin = {
  left: "xlarge",
  right: "xlarege",
};
const Archive = () => (
  <>
    <Box margin={boxMargin}>
      <Heading color="brand">Galeria zdjęć </Heading>
    </Box>
    <StyledHeading color="brand" level="3">
      01.10.2024
    </StyledHeading>
    <ImageGallery items={september2024} lazyLoad={true} />;
  </>
);
export default Archive;
