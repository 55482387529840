import emailjs from "emailjs-com";

const PUBLIC_API_KEY = "lDQNu9m1wzdUOMmF-";
const TEMPLATE_ID = "template_fallyam";
const SERVICE_ID = "service_4moptag";

const sendEmail = (form) => {
     return emailjs.send(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_API_KEY)
        .then(() => {
           return true;
        }, (error) => {
            console.log(error.text);
            return false;
        });
};

export default sendEmail;
