import { Box, Heading, Text } from "grommet";
import styled from "styled-components";

const StyledText = styled(Text)`
  line-height: 32px;
`;

const Description = ({text}) => (
  <Box>
    <Heading color="brand">Inwestycja</Heading>
    <Box>
      <StyledText>
        {text}
      </StyledText>
    </Box>
  </Box>
);

export default Description;
