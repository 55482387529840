import { Bus, Shop, Location, Car, Basket, Train } from "grommet-icons";
import fifthImage from "../assets/images/july/16.jpg";
import fourthImage from "../assets/images/july/13.jpg";
import firstImage from "../assets/images/july/3.jpg";
import secondImage from "../assets/images/july/4.jpg";
import thirdImage from "../assets/images/july/12.jpg";
import view from "../assets/images/rzut.jpg";

class SlonecznaPanoramaData {
  Attractions = [
    { place: "Przystanek MPK", length: "500m", icon: <Bus size="medium" /> },
    { place: "Sklep", length: "750m", icon: <Basket size="medium" /> },
    { place: "Wjazd na autostradę", length: "4 km", icon: <Car size="medium" /> },
    { place: "Dworzec PKP w Wieliczce", length: "6 km", icon: <Train size="medium" /> },
    { place: "Centrum Handlowe Bonarka", length: "13.5 km", icon: <Shop size="medium" /> },
    { place: "Rynek Główny w Krakowie", length: "17 km", icon: <Location size="medium" /> },
  ];

  View = view;
  HousesTable = [
    {
      id: "A",
      plotSurface: 432.8,
      gardenSurface: 337.35,
      floorSurface: 103.72,
      usableSurface: 99.15,
      price: 0,
      currency: "zł",
      symbol: "m²",
      houseState: "sold",
    },
    {
      id: "B",
      plotSurface: 265.8,
      gardenSurface: 173.6,
      floorSurface: 103.72,
      usableSurface: 99.15,
      price: 0,
      currency: "zł",
      symbol: "m²",
      houseState: "sold",
    },
    {
      id: "C",
      plotSurface: 271.63,
      gardenSurface: 179.3,
      floorSurface: 103.72,
      usableSurface: 99.15,
      price: 0,
      currency: "zł",
      symbol: "m²",
      houseState: "sold",
    },
    {
      id: "D",
      plotSurface: 161.92,
      gardenSurface: 71.72,
      floorSurface: 103.72,
      usableSurface: 99.15,
      price: 0,
      currency: "zł",
      symbol: "m²",
      houseState: "sold",
    },
    {
      id: "E",
      plotSurface: 351.85,
      gardenSurface: 259.6,
      floorSurface: 103.72,
      usableSurface: 99.15,
      price: 0,
      currency: "zł",
      symbol: "m²",
      houseState: "sold",
    },
  ];

  SliderImages = [firstImage, secondImage, thirdImage, fourthImage, fifthImage];

  InvestmentDescription = `
  Słoneczna Panorama to przytulne osiedle domów jednorodzinnych w miejscowości Sułków nieopodal Wieliczki.
  Inwestycja znajduje się w zacisznym i urokliwym miejscu, które jednocześnie zapewnia bliskość infrastruktury
  komunikacyjnej. Wykonany indywidualnie projekt budynków charakteryzuje się przemyślaną i funkcjonalną
  architekturą, zapewniającą komfort i wygodę mieszkania. Domy wykończone są w podwyższonym standardzie.
  Zastosowano m.in: rekuperację, powiększoną izolację między segmentami - 53 cm, docieplenie ścian zewnętrznych
  styropianem 20 cm, beton komórkowy i dachówkę betonową. Każdy z segmentów wyposażony jest w piec c.o i
  ogrzewanie podłogowe. Zakończenie inwestycji planowane jest na 31 lipca 2023.
  `;

  StandardList = [
    "beton komórkowy",
    "fundamenty:styrodur xps 12cm, izolacja pionowa, disperbit (masa bitumiczna)",
    "ocieplenie budynku: styropian 20cm, tynk silikatowy",
    "tynki gipsowe wewnętrzne diament",
    "ocieplenie poddasza: wełna mineralna gr. 25 cm lub pianopur",
    "odległość miedzy wewnętrzynmi segmentami 53cm",
    "dachówka antracytowa betonowa Brass",
    "instalacja gazowa z piecem c.o marki Saunier Duval, ogrzewanie podłogowe",
    "instalacja elektryczna, domofon, internetowe łącze światłowodowe",
    "rekuperacja",
    "szambo lub kanalizacja",
    "miejsce parkingowe kostka brukowa",
    "droga wewnetrzna utwardzona - kostka eko",
    "ogrodzenie z brama wjazdową i bramką",
  ];
}

const SlonecznaPanorama = new SlonecznaPanoramaData();
export default SlonecznaPanorama;
