export const list = [
  {
    key: "1",
    title: "Nowoczesne technologie",
    text: "Korzystamy ze sprawdzonych i certyfikowanych materiałów budowlanych. Stosujemy nowatorskie rozwiązania, zapewniające komfort w użytkowaniu domów.",
  },
  {
    key: "2",
    title: "Sprawdzeni partnerzy",
    text: "Polegamy tylko na sprawdzonych partnerach - generalnym wykonawcą naszych inwestycji jest firma budowlana LEŚ-BUD, która od 30 lat aktywnie działa na rynku budowlanym.",
  },
  {
    key: "3",
    title: "Własny kapitał",
    text: "Inwestycje finansujemy z środków własnych, co pozwala nam na większą elastyczność i szybsze podejmowanie decyzji.",
  },
  {
    key: "4",
    title: "Dopasowanie do potrzeb",
    text: "Sprawdź nasze standardowe wyposażenie domu i opcje, które możesz wybrać. Jeżeli chciałbyś wprowadzić dodatkowe zmiany - jesteśmy otwarci na Twoje sugestie.",
  },
];
