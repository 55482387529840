import image1 from "../../assets/images/may/1.JPG";
import image2 from "../../assets/images/may/2.JPG";
import image3 from "../../assets/images/may/3.JPG";
import image4 from "../../assets/images/may/4.JPG";
import image5 from "../../assets/images/may/5.JPG";
import image6 from "../../assets/images/may/6.JPG";
import image7 from "../../assets/images/may/7.JPG";
import image8 from "../../assets/images/may/8.JPG";
import image9 from "../../assets/images/may/9.JPG";
import image10 from "../../assets/images/may/10.JPG";
import image11 from "../../assets/images/may/11.JPG";
import image12 from "../../assets/images/may/12.JPG";
import simage1 from "../../assets/images/september/1.jpg";
import simage2 from "../../assets/images/september/2.jpg";
import simage3 from "../../assets/images/september/3.jpg";
import simage4 from "../../assets/images/september/4.jpg";
import simage5 from "../../assets/images/september/5.jpg";
import simage6 from "../../assets/images/september/6.jpg";
import simage7 from "../../assets/images/september/7.jpg";
import simage8 from "../../assets/images/september/8.jpg";
import simage9 from "../../assets/images/september/9.jpg";
import simage10 from "../../assets/images/september/10.jpg";
import simage11 from "../../assets/images/september/11.jpg";
import simage12 from "../../assets/images/september/12.jpg";
import simage13 from "../../assets/images/september/13.jpg";
import simage14 from "../../assets/images/september/14.jpg";
import simage15 from "../../assets/images/september/15.jpg";
import simage16 from "../../assets/images/september/16.jpg";
import simage17 from "../../assets/images/september/17.jpg";
import simage18 from "../../assets/images/september/18.jpg";
import simage19 from "../../assets/images/september/19.jpg";
import simage20 from "../../assets/images/september/20.jpg";
import simage21 from "../../assets/images/september/21.jpg";
import simage22 from "../../assets/images/september/22.jpg";
import simage23 from "../../assets/images/september/23.jpg";
import simage24 from "../../assets/images/september/24.jpg";
import simage25 from "../../assets/images/september/25.jpg";
import simage26 from "../../assets/images/september/26.jpg";
import simage27 from "../../assets/images/september/27.jpg";
import simage28 from "../../assets/images/september/28.jpg";
import simage29 from "../../assets/images/september/29.jpg";
import simage30 from "../../assets/images/september/30.jpg";
import simage31 from "../../assets/images/september/31.jpg";
import simage32 from "../../assets/images/september/32.jpg";
import simage33 from "../../assets/images/september/33.jpg";
import simage34 from "../../assets/images/september/34.jpg";
import dimage1 from "../../assets/images/december/1.jpg";
import dimage2 from "../../assets/images/december/2.jpg";
import dimage3 from "../../assets/images/december/3.jpg";
import dimage4 from "../../assets/images/december/4.jpg";
import dimage5 from "../../assets/images/december/5.jpg";
import dimage6 from "../../assets/images/december/6.jpg";
import dimage7 from "../../assets/images/december/7.jpg";
import dimage8 from "../../assets/images/december/8.jpg";
import dimage9 from "../../assets/images/december/9.jpg";
import dimage10 from "../../assets/images/december/10.jpg";
import dimage11 from "../../assets/images/december/11.jpg";
import dimage12 from "../../assets/images/december/12.jpg";
import dimage13 from "../../assets/images/december/13.jpg";
import dimage14 from "../../assets/images/december/14.jpg";
import dimage15 from "../../assets/images/december/15.jpg";
import dimage16 from "../../assets/images/december/16.jpg";
import dimage17 from "../../assets/images/december/17.jpg";
import dimage18 from "../../assets/images/december/18.jpg";
import dimage19 from "../../assets/images/december/19.jpg";

import aimage1 from "../../assets/images/april/IMG_7819.jpg";
import aimage2 from "../../assets/images/april/IMG_7820.jpg";
import aimage3 from "../../assets/images/april/IMG_7821.jpg";
import aimage4 from "../../assets/images/april/IMG_7822.jpg";
import aimage5 from "../../assets/images/april/IMG_7823.jpg";
import aimage6 from "../../assets/images/april/IMG_7824.jpg";
import aimage7 from "../../assets/images/april/IMG_7825.jpg";
import aimage8 from "../../assets/images/april/IMG_7826.jpg";
import aimage9 from "../../assets/images/april/IMG_7827.jpg";
import aimage10 from "../../assets/images/april/IMG_7828.jpg";
import aimage11 from "../../assets/images/april/IMG_7829.jpg";
import aimage12 from "../../assets/images/april/IMG_7830.jpg";
import aimage13 from "../../assets/images/april/IMG_7831.jpg";
import aimage14 from "../../assets/images/april/IMG_7832.jpg";
import aimage15 from "../../assets/images/april/IMG_7833.jpg";
import aimage16 from "../../assets/images/april/IMG_7834.jpg";
import aimage17 from "../../assets/images/april/IMG_7835.jpg";
import aimage18 from "../../assets/images/april/IMG_7836.jpg";
import jimage1 from "../../assets/images/july/1.jpg";
import jimage2 from "../../assets/images/july/2.jpg";
import jimage3 from "../../assets/images/july/3.jpg";
import jimage4 from "../../assets/images/july/4.jpg";
import jimage5 from "../../assets/images/july/5.jpg";
import jimage6 from "../../assets/images/july/6.jpg";
import jimage7 from "../../assets/images/july/7.jpg";
import jimage8 from "../../assets/images/july/8.jpg";
import jimage9 from "../../assets/images/july/9.jpg";
import jimage10 from "../../assets/images/july/10.jpg";
import jimage11 from "../../assets/images/july/11.jpg";
import jimage12 from "../../assets/images/july/12.jpg";
import s2image1 from "../../assets/images/september2024/1.jpg";
import s2image2 from "../../assets/images/september2024/2.jpg";
import s2image3 from "../../assets/images/september2024/3.jpg";
import s2image4 from "../../assets/images/september2024/4.jpg";
import s2image5 from "../../assets/images/september2024/5.jpg";
import ImageGallery from "react-image-gallery";
import { Box, Heading } from "grommet";
import "./archiveGallery.css";
import styled from "styled-components";

const StyledHeading = styled(Heading)`
  margin: 20px auto;
`;
const oldImages = [
  {
    original: image1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: image2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: image3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: image4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: image5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
  {
    original: image6,
    originalAlt: "Zdjęcie nr 6 domu jednorodzinnego",
  },
  {
    original: image7,
    originalAlt: "Zdjęcie nr 7 domu jednorodzinnego",
  },
  {
    original: image8,
    originalAlt: "Zdjęcie nr 8 domu jednorodzinnego",
  },
  {
    original: image9,
    originalAlt: "Zdjęcie nr 9 domu jednorodzinnego",
  },
  {
    original: image10,
    originalAlt: "Zdjęcie nr 10 domu jednorodzinnego",
  },
  {
    original: image11,
    originalAlt: "Zdjęcie nr 11 domu jednorodzinnego",
  },
  {
    original: image12,
    originalAlt: "Zdjęcie nr 12 domu jednorodzinnego",
  },
];

const firstSeptember = [
  {
    original: simage1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: simage2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: simage3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: simage4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: simage5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
  {
    original: simage6,
    originalAlt: "Zdjęcie nr 6 domu jednorodzinnego",
  },
  {
    original: simage7,
    originalAlt: "Zdjęcie nr 7 domu jednorodzinnego",
  },
  {
    original: simage8,
    originalAlt: "Zdjęcie nr 8 domu jednorodzinnego",
  },
  {
    original: simage9,
    originalAlt: "Zdjęcie nr 9 domu jednorodzinnego",
  },
  {
    original: simage10,
    originalAlt: "Zdjęcie nr 10 domu jednorodzinnego",
  },
  {
    original: simage11,
    originalAlt: "Zdjęcie nr 11 domu jednorodzinnego",
  },
  {
    original: simage12,
    originalAlt: "Zdjęcie nr 12 domu jednorodzinnego",
  },
  {
    original: simage13,
    originalAlt: "Zdjęcie nr 13 domu jednorodzinnego",
  },
  {
    original: simage14,
    originalAlt: "Zdjęcie nr 14 domu jednorodzinnego",
  },
  {
    original: simage15,
    originalAlt: "Zdjęcie nr 15 domu jednorodzinnego",
  },
  {
    original: simage16,
    originalAlt: "Zdjęcie nr 16 domu jednorodzinnego",
  },
  {
    original: simage17,
    originalAlt: "Zdjęcie nr 17 domu jednorodzinnego",
  },
  {
    original: simage18,
    originalAlt: "Zdjęcie nr 18 domu jednorodzinnego",
  },
  {
    original: simage19,
    originalAlt: "Zdjęcie nr 19 domu jednorodzinnego",
  },
  {
    original: simage20,
    originalAlt: "Zdjęcie nr 20 domu jednorodzinnego",
  },
  {
    original: simage21,
    originalAlt: "Zdjęcie nr 21 domu jednorodzinnego",
  },
  {
    original: simage22,
    originalAlt: "Zdjęcie nr 22 domu jednorodzinnego",
  },
  {
    original: simage23,
    originalAlt: "Zdjęcie nr 23 domu jednorodzinnego",
  },
  {
    original: simage24,
    originalAlt: "Zdjęcie nr 24 domu jednorodzinnego",
  },
  {
    original: simage25,
    originalAlt: "Zdjęcie nr 25 domu jednorodzinnego",
  },
];

const secondSeptember = [
  {
    original: simage26,
    originalAlt: "Zdjęcie nr 26 domu jednorodzinnego",
  },
  {
    original: simage27,
    originalAlt: "Zdjęcie nr 27 domu jednorodzinnego",
  },
  {
    original: simage28,
    originalAlt: "Zdjęcie nr 28 domu jednorodzinnego",
  },
  {
    original: simage29,
    originalAlt: "Zdjęcie nr 29 domu jednorodzinnego",
  },
  {
    original: simage30,
    originalAlt: "Zdjęcie nr 30 domu jednorodzinnego",
  },
  {
    original: simage31,
    originalAlt: "Zdjęcie nr 31 domu jednorodzinnego",
  },
  {
    original: simage32,
    originalAlt: "Zdjęcie nr 32 domu jednorodzinnego",
  },
  {
    original: simage33,
    originalAlt: "Zdjęcie nr 33 domu jednorodzinnego",
  },
  {
    original: simage34,
    originalAlt: "Zdjęcie nr 34 domu jednorodzinnego",
  },
];

const december = [
  {
    original: dimage10,
    originalAlt: "Zdjęcie nr 10 domu jednorodzinnego",
  },
  {
    original: dimage11,
    originalAlt: "Zdjęcie nr 11 domu jednorodzinnego",
  },
  {
    original: dimage12,
    originalAlt: "Zdjęcie nr 12 domu jednorodzinnego",
  },
  {
    original: dimage13,
    originalAlt: "Zdjęcie nr 13 domu jednorodzinnego",
  },
  {
    original: dimage14,
    originalAlt: "Zdjęcie nr 14 domu jednorodzinnego",
  },
  {
    original: dimage15,
    originalAlt: "Zdjęcie nr 15 domu jednorodzinnego",
  },
  {
    original: dimage16,
    originalAlt: "Zdjęcie nr 16 domu jednorodzinnego",
  },
  {
    original: dimage17,
    originalAlt: "Zdjęcie nr 17 domu jednorodzinnego",
  },
  {
    original: dimage18,
    originalAlt: "Zdjęcie nr 18 domu jednorodzinnego",
  },
  {
    original: dimage19,
    originalAlt: "Zdjęcie nr 19 domu jednorodzinnego",
  },
  {
    original: dimage1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: dimage2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: dimage3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: dimage4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: dimage5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
  {
    original: dimage6,
    originalAlt: "Zdjęcie nr 6 domu jednorodzinnego",
  },
  {
    original: dimage7,
    originalAlt: "Zdjęcie nr 7 domu jednorodzinnego",
  },
  {
    original: dimage8,
    originalAlt: "Zdjęcie nr 8 domu jednorodzinnego",
  },
  {
    original: dimage9,
    originalAlt: "Zdjęcie nr 9 domu jednorodzinnego",
  },
];

const april = [
  {
    original: aimage1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: aimage2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: aimage3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: aimage4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: aimage5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
  {
    original: aimage6,
    originalAlt: "Zdjęcie nr 6 domu jednorodzinnego",
  },
  {
    original: aimage7,
    originalAlt: "Zdjęcie nr 7 domu jednorodzinnego",
  },
  {
    original: aimage8,
    originalAlt: "Zdjęcie nr 8 domu jednorodzinnego",
  },
  {
    original: aimage9,
    originalAlt: "Zdjęcie nr 9 domu jednorodzinnego",
  },
  {
    original: aimage10,
    originalAlt: "Zdjęcie nr 10 domu jednorodzinnego",
  },
  {
    original: aimage11,
    originalAlt: "Zdjęcie nr 11 domu jednorodzinnego",
  },
  {
    original: aimage12,
    originalAlt: "Zdjęcie nr 12 domu jednorodzinnego",
  },
  {
    original: aimage13,
    originalAlt: "Zdjęcie nr 13 domu jednorodzinnego",
  },
  {
    original: aimage14,
    originalAlt: "Zdjęcie nr 14 domu jednorodzinnego",
  },
  {
    original: aimage15,
    originalAlt: "Zdjęcie nr 15 domu jednorodzinnego",
  },
  {
    original: aimage16,
    originalAlt: "Zdjęcie nr 16 domu jednorodzinnego",
  },
  {
    original: aimage17,
    originalAlt: "Zdjęcie nr 17 domu jednorodzinnego",
  },
  {
    original: aimage18,
    originalAlt: "Zdjęcie nr 18 domu jednorodzinnego",
  },
];

const july = [
  {
    original: jimage1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: jimage2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: jimage3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: jimage4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: jimage5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
  {
    original: jimage6,
    originalAlt: "Zdjęcie nr 6 domu jednorodzinnego",
  },
  {
    original: jimage7,
    originalAlt: "Zdjęcie nr 7 domu jednorodzinnego",
  },
  {
    original: jimage8,
    originalAlt: "Zdjęcie nr 8 domu jednorodzinnego",
  },
  {
    original: jimage9,
    originalAlt: "Zdjęcie nr 9 domu jednorodzinnego",
  },
  {
    original: jimage10,
    originalAlt: "Zdjęcie nr 10 domu jednorodzinnego",
  },
  {
    original: jimage11,
    originalAlt: "Zdjęcie nr 11 domu jednorodzinnego",
  },
  {
    original: jimage12,
    originalAlt: "Zdjęcie nr 12 domu jednorodzinnego",
  },
];
const september2024 = [
  {
    original: s2image1,
    originalAlt: "Zdjęcie nr 1 domu jednorodzinnego",
  },
  {
    original: s2image2,
    originalAlt: "Zdjęcie nr 2 domu jednorodzinnego",
  },
  {
    original: s2image3,
    originalAlt: "Zdjęcie nr 3 domu jednorodzinnego",
  },
  {
    original: s2image4,
    originalAlt: "Zdjęcie nr 4 domu jednorodzinnego",
  },
  {
    original: s2image5,
    originalAlt: "Zdjęcie nr 5 domu jednorodzinnego",
  },
];

const boxMargin = {
  left: "xlarge",
  right: "xlarege",
};
const ArchiveGallery = () => (
  <>
    <Box margin={boxMargin}>
      <Heading color="brand">Galeria zdjęć </Heading>
    </Box>
    <StyledHeading color="brand" level="3">
      30.09.2023
    </StyledHeading>
    <ImageGallery items={september2024} lazyLoad={true} />;
    <StyledHeading color="brand" level="3">
      17.07.2023
    </StyledHeading>
    <ImageGallery items={july} lazyLoad={true} />;
    <StyledHeading color="brand" level="3">
      22.04.2023
    </StyledHeading>
    <ImageGallery items={april} lazyLoad={true} />;
    <StyledHeading color="brand" level="3">
      21.12.2022
    </StyledHeading>
    <ImageGallery items={december} lazyLoad={true} />;
    <StyledHeading color="brand" level="3">
      17.09.2022
    </StyledHeading>
    <ImageGallery items={secondSeptember} lazyLoad={true} />;
    <StyledHeading color="brand" level="3">
      06.09.2022
    </StyledHeading>
    <ImageGallery items={firstSeptember} lazyLoad={true} />;
    <StyledHeading color="brand" level="3">
      15.05.2022
    </StyledHeading>
    <ImageGallery items={oldImages} lazyLoad={true} />;
  </>
);
export default ArchiveGallery;
