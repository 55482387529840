import React from "react";
import { Box, List, Text } from "grommet";
import styled from "styled-components";

const StyledBox = styled(Box)`
display:inline-block;
`


const AttractionList = ({ attractions}) => (
  <StyledBox height="100%" align="center" alignContent="center" justify="center">
    <List data={attractions} pad="small" border={false}>
      {(datum) => (
          <Box direction="row-responsive" gap="small" align="center">
              {datum.icon}
            <Text weight="bold">{datum.place}</Text>
            <Text weight="bold">{datum.length}</Text>
          </Box>
      )}
    </List>
  </StyledBox>
);

export default AttractionList;
