import { Heading, Box } from "grommet";
import styled from "styled-components";

const StyledUl = styled.ul`
  list-style: none;
`;
const StyledLi = styled.li`
  margin-top: 17px;
  &:before {
    content: "■";
    color: #2a388b;
    padding-right:7px;
  }
`;



const Standard = ({ standardList }) => (
  <Box>
    <Heading color="brand">Standard wykończenia</Heading>
    <Box align="center" justify="center">
      <StyledUl>
        {standardList.map((l, i) => (
          <StyledLi key={i}>{l}</StyledLi>
        ))}
      </StyledUl>
    </Box>
  </Box>
);

export default Standard;
