import AboutUs from "../components/AboutUs";
import InvestmentAdd from "../components/InvestmentAd/InvestmentAdd";
import { Box } from "grommet";
import ContactForm from "../components/ContactForm";
import LandingImage from "../components/LandingImage";

const MainPage = () => (
  <Box animation="fadeIn">
    <LandingImage />
    <Box
      margin={{
        horizontal: "xlarge",
        top: "xlarge",
        bottom: "medium",
      }}
      gap="xlarge"
    >
      <AboutUs />
      <InvestmentAdd />
      <ContactForm />
    </Box>
  </Box>
);

export default MainPage;
