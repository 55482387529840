import { Box, Heading, Text } from "grommet";
import List from "./List";

const AboutUs = () => (
  <>
    <Heading color="brand" id="about-us">
      O nas
    </Heading>
    <Box gridArea="header">
      <Text>
        W naszych inwestycjach stawiamy na nowoczesny design i praktyczny układ mieszkań. Budowane przez nas domy
        charakteryzują się wysokim standardem wykończenia oraz nowoczesnymi rozwiązaniami technologicznymi.
      </Text>
    </Box>
    <Box gridArea="list">
      <List />
    </Box>
  </>
);

export default AboutUs;
