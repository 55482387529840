import React, { useEffect} from "react";
import { Grommet } from "grommet";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { HashRouter, Routes, Route } from "react-router-dom";
import InvestmentPage from "./pages/InvestmentPage";
import MainPage from "./pages/MainPage";
import ReactGA from 'react-ga';
import SulkowZaciszePage from "./pages/SulkowZaciszePage";
export const AppContext = React.createContext(null);

function App() {
  const theme = {
    global: {
      font: {
        family: "Roboto",
        size: "18px",
        height: "20px",
      },
      colors: {
        brand: "#2a388b",
        control: "#FFFFFF",
        secondary: "#FFFFFF",
        border: "#A6A6A6",
        lightbrand: "#949BC5",
      },
      focus: {
        border: {
          color: "#2a388b",
        },
      },
      input: {
        font: {
          weight: "400",
        },
      },
      active: {
        background: {
          color: "#2a388b",
        },
      },
    },
    menu: {
 
    },
    anchor: {
      hover: {
        extend: "text-decoration-thickness: 2px;text-underline-offset: 8px",
      },
    },
    checkBox: {
      color: "#2a388b",
      border: {
        color: "#2a388b",
      },
      extend: {
        alignItems: "start",
      },
    },
    button: {
      primary: {
        background: {
          color: "#2a388b",
        },
        border: {
          color: "#2a388b",
          width: "2px",
        },
      },
      border: {
        color: "#2a388b",
        width: "2px",
      },
      padding: {
        vertical: "4px",
        horizontal: "22px",
      },
    },
    table: {
      header: {
        extend: "font-weight:600",
      },
    },
  };

  useEffect(() => {
    ReactGA.initialize('G-9SLTK1LYYZ');
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  
  return (
    <Grommet theme={theme}>
      <HashRouter>
          <Navbar theme={theme} />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/investment" element={<InvestmentPage />} />
            <Route path="/sulkow-zacisze" element={<SulkowZaciszePage />} />
          </Routes>
        <Footer theme={theme} />
      </HashRouter>
    </Grommet>
  );
}

export default App;
