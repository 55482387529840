import { MapContainer, TileLayer, Marker } from "react-leaflet";
import styled from "styled-components";

const StyledDiv = styled.div`
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display:inline-block;
`;
const StyledMapContainer = styled(MapContainer)`
  height: 100%;
  width: 100%;
  display:inline-block;
`;


const Map = ({coords}) => (
  <StyledDiv>
    <StyledMapContainer center={coords} zoom={13} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        crossOrigin=""
      />
      <Marker position={coords}></Marker>
    </StyledMapContainer>
  </StyledDiv>
);

export default Map;
